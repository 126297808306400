<template>
  <div class="flex-shrink-0">
    <div v-if="variant === 'text'">{{ badge }}.</div>
    <div
      v-else-if="variant === 'textColor'"
      :class="badgeColor"
      class="fw-bold font-monospace"
    >{{ badge }}</div>
    <div
      v-else
      :class="badgeBackground"
      class="e-badge small text-white"
    >{{ badge }}</div>
  </div>
</template>

<script>
export default {
  name: 'ExerciseBadge',
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    exercises: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    badgeColor() {
      const ascii = this.badge.charCodeAt(0);
      const index = Math.max(0, ascii - 65);
      switch (index % 3) {
        case 0:
          return 't-primary';
        case 1:
          return 't-secondary';
        default:
          return 't-tertiary';
      }
    },
    badgeBackground() {
      const ascii = this.badge.charCodeAt(0);
      const index = Math.max(0, ascii - 65);
      switch (index % 3) {
        case 0:
          return 'b-primary';
        case 1:
          return 'b-secondary';
        default:
          return 'b-tertiary';
      }
    },
    badge() {
      const { exerciseId } = this.exercise;
      const buckets = [];
      let a = 0;
      let n = 0;

      this.exercises.forEach((exercise) => {
        if (exercise.type === 'section') {
          buckets.push({ isSection: true });
          return;
        }

        if (exercise.isSuperset) {
          if (buckets.length && !buckets[buckets.length - 1].isSection) {
            buckets[buckets.length - 1].push(exercise.exerciseId);
          } else {
            buckets.push([exercise.exerciseId]);
          }
        } else {
          buckets.push([exercise.exerciseId]);
        }
      });

      let cursor = -1;
      buckets.forEach((bucket) => {
        if (bucket.isSection) {
          cursor = -1;
        } else {
          cursor += 1;
          bucket.forEach((item, itemIdx) => {
            if (item === exerciseId) {
              a = cursor;
              n = itemIdx;
            }
          });
        }
      });

      return `${String.fromCharCode(65 + a)}${n + 1}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/vars.scss";

$size: 2rem;

.b-primary {
  background-color: $primaryAccent;
}

.b-secondary {
  background-color: $secondaryAccent;
}

.b-tertiary {
  background-color: $tertiaryAccent;
}

.t-primary {
  color: $primaryAccent;
}

.t-secondary {
  color: $secondaryAccent;
}

.t-tertiary {
  color: $tertiaryAccent;
}

.e-badge {
  user-select: none;
  font-weight: 500;
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  border-radius: 50%;
  margin: auto;
}
</style>
